<div class="backdrop">
  <section class="details-page">
    <app-gallery-not-modal
      *ngIf="!isInOneColum"
      [layoutMode]="'vert'"
      [thumbs]="thumbImages ?? []"
      [original]="originalImages ?? []"
      [has3dTour]="property?.has3DTour"
      (takeTour)="openModal(ModalEnum.TAKE_TOUR)"
    ></app-gallery-not-modal>
    <article class="all-info-property">
      <div class="back-btn">
        <button class="btn btn-back" (click)="back()">
          <span>{{ 'property_details.back_to_properties' | translate }}</span>
        </button>
      </div>
      <app-details-header
        [isLoggedUser]="isLoggedUser"
        [isSaved]="property?.isSavedHome"
        [isHidden]="property?.isHidden"
        [property]="property"
        (save)="saveHandler($event)"
        (hide)="hideHandler($event)"
      ></app-details-header>
      <app-gallery-not-modal
        *ngIf="isInOneColum"
        [layoutMode]="'hor'"
        [thumbs]="thumbImages ?? []"
        [original]="originalImages ?? []"
        [has3dTour]="property?.has3DTour"
        (takeTour)="openModal(ModalEnum.TAKE_TOUR)"
      ></app-gallery-not-modal>
      <app-details-first-info
        [property]="property"
        (takeTour)="openModal(ModalEnum.TAKE_TOUR)"
        (contactAgent)="openModal(ModalEnum.CONTACT_AGENT)"
      ></app-details-first-info>
      <!--      <app-details-tabs></app-details-tabs> -->
      <app-details-schema *ngIf="property?.combined_details?.length > 0" [property]="property"></app-details-schema>
      <app-details-overview [property]="property"></app-details-overview>
      <app-details-providers [broker]="property?.broker"></app-details-providers>
      <app-take-a-tour
        [propertyId]="propertyId"
        [agentEmail]="property?.broker?.email ?? ''"
      ></app-take-a-tour>
      <!--      <app-details-facts-and-featured></app-details-facts-and-featured>-->
      <!--      <app-contact-buyers-form></app-contact-buyers-form> -->

      <!-- will be calculators later -->
      <!--      <app-details-nearby-schools></app-details-nearby-schools>--> <!-- will be later -->
      <app-details-similar-properties
        *ngIf="propertySimilar?.length > 0"
        [propertySimilar]="propertySimilar"
        (changeProperty)="changePropertyHandler($event)"
      ></app-details-similar-properties>
      <app-details-footer></app-details-footer>
    </article>
  </section>
</div>

<app-basic-modal [modalId]="ModalEnum.TAKE_TOUR">
  <app-take-a-tour
    [propertyId]="propertyId"
    [agentEmail]="property?.broker?.email ?? ''"
  ></app-take-a-tour>
</app-basic-modal>
<app-basic-modal [modalId]="ModalEnum.CONTACT_AGENT">
  <app-contact-buyers-form
    [propertyId]="propertyId"
    [agentEmail]="property?.broker?.email ?? ''"
  ></app-contact-buyers-form>
</app-basic-modal>
