export enum PropertySortEnum {
  Newest = '1',
  PriceAsc = '2',
  PriceDesc = '3',
  Rooms = '8',
  Bedrooms = '4',
  Bathrooms = '5',
  HomeSize = '6',
  LotSize = '7',
}
