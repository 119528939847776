import { FilterNameEnum } from '@enums';
import { ISearchFilter } from '@interfaces';
import { parseToNumber } from './parse-to-number.utils';

export function propertyValuesToNumber(filter: ISearchFilter): Partial<ISearchFilter> {
  const numberFields = [
    FilterNameEnum.roomsNumber,
    FilterNameEnum.bathroomNumber,
    FilterNameEnum.bedroomNumber,
    FilterNameEnum.homeSizeFrom,
    FilterNameEnum.homeSizeTo,
    FilterNameEnum.lotSizeFrom,
    FilterNameEnum.lotSizeTo,
    FilterNameEnum.parkingNumber,
    FilterNameEnum.priceFrom,
    FilterNameEnum.priceTo,
    FilterNameEnum.propertyType,
    FilterNameEnum.yearBuildFrom,
    FilterNameEnum.yearBuildTo,
  ];
  const arrayFields = [FilterNameEnum.condition, FilterNameEnum.homeType];

  const result = {};

  numberFields
    .filter((f) => filter[f]?.length)
    .forEach((f) => {
      const res = parseToNumber(filter[f]);
      result[f] = res > 0 ? res : undefined;
    });
  arrayFields.forEach(
    (f) => (result[f] = !!filter[f] && filter[f].length ? filter[f]?.map((i) => parseToNumber(i)) : undefined),
  );

  return result;
}
