import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-markers-in-cluster',
  templateUrl: './modal-markers-in-cluster.component.html',
  styleUrls: ['./modal-markers-in-cluster.component.scss'],
})
export class ModalMarkersInClusterComponent {
  @Input() markers: any;

  constructor(private router: Router) {}

  seeDetails(event, marker) {
    if (event.target.tagName === 'IMG') {
      const address = (marker.addressListing ?? marker.fullAddress)
        .replace(/[^\w]/gi, '-')
        .replace('--', '-')
        .toLowerCase();
      const rooms = marker.rooms ? `${marker.rooms}-rooms-` : '';
      const bedroom = marker.bedroom ? `${marker.bedroom}-bedroom-` : '';
      this.router.navigate([`/property/${marker.id}/${rooms}${bedroom}${address}`]);
    }
  }
}
