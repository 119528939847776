import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store';
import { environment } from '@env';
import { MapSettingService } from './map-setting.service';
import { CONTAINER_MAP } from 'app/shared/helpers';
import { SavedSearchesService } from './saved-searches.service';
import { IInitSearchParams } from '@interfaces';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageEnum } from '@enums';
import { BaseMapService } from './base-map-service';

declare let require: any;

@Injectable({
  providedIn: 'root',
})
export class MapService extends BaseMapService {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private store: Store<AppState>,
    private mapSetting: MapSettingService,
    private savedSearchesService: SavedSearchesService,
    private ls: LocalStorageService,
  ) {
    super(platformId);
  }

  setupMap(data: { zoom?: number; lat?: string; lon?: string }) {
    const localMap = this.savedSearchesService.loadLastMapSettingToLocalStorage();
    let lat = data?.lat ?? this.mapSetting.defaultParametersMap.lat;
    let lon = data?.lon ?? this.mapSetting.defaultParametersMap.lon;
    let zoom = data?.zoom ?? this.mapSetting.defaultZoomMap;

    if (environment.typeEnv === 'stage' || environment.typeEnv === 'dev') {
      lat = this.mapSetting.defaultParametersMap.lat;
      lon = this.mapSetting.defaultParametersMap.lon;
    }

    if (localMap && localMap.centerMap && localMap.currentZoom) {
      lat = localMap.centerMap.lat;
      lon = localMap.centerMap.lng;
      zoom = localMap.currentZoom;
    }

    this.map = this.L.map(CONTAINER_MAP, {
      minZoom: this.mapSetting.defaultMinZoomMap,
      maxZoom: this.mapSetting.defaultMaxZoomMap,
    }).setView([lat, lon], zoom);

    this.removeDefaultIcon();
    this.maxBounds = this.map.getBounds();
    this.maxBounds = this.maxBounds.pad(this.padValue).contains({ lat, lng: lon });

    this.tileLayer = this.L.tileLayer(environment.TILES_MAP, {
      maxZoom: this.mapSetting.defaultMaxZoomMap,
    });
    this.map.addLayer(this.tileLayer);
    return this.map;
  }

  removeDefaultIcon() {
    delete this.L.Icon.Default.prototype._getIconUrl;
    this.L.Icon.Default.mergeOptions({
      iconUrl: this.defaultIcon,
      shadowUrl: null,
      iconRetinaUrl: this.defaultIcon,
    });

    this.L.Icon.Default.mergeOptions({
      iconSize: [0, 0],
      shadowSize: [0, 0],
    });
  }

  getInitParams(): IInitSearchParams {
    return this.ls.getItem(LocalStorageEnum.InitSearchParams) ?? {};
  }

  saveInitParams(params: IInitSearchParams): void {
    this.ls.setItem(LocalStorageEnum.InitSearchParams, params);
  }

  updateInitParams(params: IInitSearchParams): void {
    const initParams = this.getInitParams();
    this.saveInitParams({ ...initParams, ...params });
  }
}
