import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalStorageEnum, ModalEnum } from '@enums';
import { environment } from '@env';
import { GeoService, provideDestroyService } from '@service';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [provideDestroyService()],
})
export class AppRootComponent implements OnInit {
  protected readonly ModalEnum = ModalEnum;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private geoService: GeoService,
    private ls: LocalStorageService,
    private http: HttpClient,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.ls.initLocalStorage();
    }

    if (!environment.production && !this.ls.getItem(LocalStorageEnum.CURRENT_LANGUAGES_STATE)) {
      this.checkMissingLangVariables();
    }

    if (this.ls.getItem(LocalStorageEnum.GeoUserInfo)) {
      return;
    }

    if (!environment.production) {
      this.geoService.getCityUser().subscribe((res) => {
        this.ls.setItem(LocalStorageEnum.GeoUserInfo, res.body);
      });
    }

    this.geoService
      .getUserInfo()
      .pipe(switchMap((res) => this.geoService.getCityUser(res.headers.get('CF-Connecting-IP'))))
      .subscribe((res) => {
        this.ls.setItem(LocalStorageEnum.GeoUserInfo, res.body);
      });
  }

  ngOnInit() {
    // if (environment.production) {
    //   this.loadGoogleAnalytics();
    //   this.loadGoogleTagManager();
    // }
  }

  private checkMissingLangVariables(): void {
    // const fileNames = ['en', 'de', 'cz', 'it', 'pl', 'sk', 'ua', 'ru'];
    const fileNames = ['en', 'sk', 'ua', 'ru'];
    const requests = fileNames.map((file) => this.http.get(`assets/i18n/${file}.json`));

    const fillMissingKeys = (source: any, target: any): any => {
      const result = {};
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
            const subResult = fillMissingKeys(source[key], target[key] || {});
            if (Object.keys(subResult).length > 0) {
              result[key] = subResult;
            }
          } else if (!target || !target.hasOwnProperty(key)) {
            result[key] = source[key];
          }
        }
      }
      return result;
    };

    forkJoin(requests).subscribe((responses) => {
      const res = {};
      fileNames.forEach((file, index) => {
        res[file] = fillMissingKeys(responses[0], responses[index]);
      });
      this.ls.setItem(LocalStorageEnum.CURRENT_LANGUAGES_STATE, res);
      console.log('>>> if something is missing - its in here: ', res);
    });
  }

  protected readonly environment = environment;
}
